<script>
import debounce from 'lodash-es/debounce'
import head from 'lodash-es/head'
import isNil from 'lodash-es/isNil'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import extractVideoThumbnail from './extract-video-thumbnail'

export default {
  name: 'gallery-thumbnails',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    videoIconSize: {
      type: Number,
      default: 24,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    activeItemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showNav: false,
      refContainer: '',
      refActiveThumb: '',
      disableButtonPrev: false,
      disableButtonNext: false,
    }
  },
  watch: {
    activeItemIndex(value) {
      if (this.$refs) {
        const activeThumb = `thumb${value}`

        this.refActiveThumb = head(this.$refs[activeThumb])
        this.centerActiveThumbnail()
        this.disableButtons()
      }
    },
  },
  mounted() {
    this.initButtons()
    window.addEventListener('resize', this.initButtons)
  },
  methods: {
    isVideo(item) {
      return item.type === 'video'
    },
    onHandleActive(index) {
      this.$emit('active-item', index)
    },
    isActiveThumb(index) {
      return this.activeItemIndex === index
    },
    thumbnailGenerator(item) {
      const source = item.cdn

      if (!this.isVideo(item)) {
        if (source && source.match('https://ucarecdn')) {
          return `${source}-/scale_crop/160x160/center/`
        } else if (source && source.match('https://media.giphy.com')) {
          const gifUrl = source.replace('giphy.gif', '200.gif')

          return gifUrl
        } else {
          return source
        }
      } else {
        return extractVideoThumbnail(source, '160x160')
      }
    },
    showPrev() {
      const amountToScrollLeft = Math.max(
        this.refContainer.scrollLeft - this.refActiveThumb.offsetWidth,
        0
      )

      this.refContainer.scrollTo(amountToScrollLeft, 0)
      this.disableButtons()
    },
    showNext() {
      const amountToScrollRight =
        this.refContainer.scrollLeft + this.refActiveThumb.offsetWidth

      this.refContainer.scrollTo(amountToScrollRight, 0)
      this.disableButtons()
    },
    initButtons() {
      if (this.$refs && !isNil(this.$refs.innerContainer)) {
        this.refContainer = this.$refs.innerContainer
        // If container has scroll, show buttons
        this.showNav =
          this.refContainer.scrollWidth > this.refContainer.offsetWidth
        this.disableButtons()
      }
    },
    disableButtons: debounce(function () {
      const containerScrollLeftPosition = this.refContainer.scrollLeft
      const containerScrollWidth = this.refContainer.scrollWidth
      const containerWidth = this.refContainer.offsetWidth

      this.disableButtonPrev = containerScrollLeftPosition === 0
      this.disableButtonNext =
        containerScrollWidth <= containerWidth + containerScrollLeftPosition
    }, 400),
    centerActiveThumbnail() {
      const lengthToScroll =
        this.refActiveThumb.offsetLeft +
        this.refActiveThumb.offsetWidth / 2 -
        this.refContainer.offsetWidth / 2

      this.refContainer.scrollTo(lengthToScroll, 0)
    },
  },
}
</script>

<template>
  <div class="absolute bottom-0 z-1 flex w-full items-center p-2.5 sm:p-5">
    <base-button
      v-if="showNav"
      :disabled="disableButtonPrev"
      class="mr-2.5 text-white md:mr-5"
      icon="rec/arrow-left"
      size="md"
      @click="showPrev()"
    />

    <div
      ref="innerContainer"
      class="flex flex-1 flex-row flex-nowrap overflow-auto"
    >
      <div
        v-for="(item, index) in sources"
        :ref="`thumb${index}`"
        :key="index"
        class="relative mr-[5px] flex h-16 w-16 shrink-0 cursor-pointer items-center justify-center rounded border-4 border-transparent bg-cover bg-no-repeat sm:h-20 sm:w-20"
        :class="[isActiveThumb(index) && 'border-white']"
        :style="{
          backgroundImage: `url(${thumbnailGenerator(item)})`,
        }"
        @click="onHandleActive(index)"
      >
        <template v-if="isVideo(item)">
          <base-icon
            :size="videoIconSize"
            svg="rec/play-circle"
            class="absolute text-white"
          />
          <video
            :src="item.cdn"
            class="h-full max-h-full w-full object-cover"
          />
        </template>
      </div>
    </div>

    <base-button
      v-if="showNav"
      :disabled="disableButtonNext"
      class="ml-2.5 text-white md:ml-5"
      icon="rec/arrow-right"
      size="md"
      @click="showNext()"
    />
  </div>
</template>
